@import './Mixin.sass'
.cta
  position: fixed
  bottom: 80px
  right: 30px
  display: flex
  flex-direction: column
  gap: 20px
  @include mobile
    right: 5px
  img
    cursor: pointer
    @include mobile
      width: 40px