*
  box-sizing: border-box
  
body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  box-sizing: border-box

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

@font-face
  font-family: 'archive'
  src: url('./assets/fonts/archive/ArchivGrotesk-Regular.otf')

@font-face
  font-family: 'archiveBold'
  src: url('./assets/fonts/archive/ArchivGrotesk-Bold.otf')

@font-face
  font-family: 'archiveLight'
  src: url('./assets/fonts/archive/ArchivGrotesk-Light.otf')

@font-face
  font-family: 'amsiPro'
  src: url('./assets/fonts/amsi/TTF/AmsiPro-Regular.ttf')

@font-face
  font-family: 'amsiProBold'
  src: url('./assets/fonts/amsi/TTF/AmsiPro-Bold.ttf')

@font-face
  font-family: 'amsiProLight'
  src: url('./assets/fonts/amsi/TTF/AmsiPro-Light.ttf')