@import '../styles/Mixin.sass'
.book
  background: #E6E2DB
  display: flex
  padding: 80px 30px
  position: relative
  @include mobile
    flex-direction: column
    align-items: center
    padding: 0
  

  p.title
    position: absolute
    top: 20px
    left: 30px
    font-family: 'archive'
    color: #615751
    font-size: 20px
    z-index: 99
    margin: 0
    @include mobile
      font-size: 12px
      left: 10px

  .image
    width: 50%
    @include mobile
      width: 100%
      padding-top: 40px
    img
      width: 100%
  .form
    width: 50%
    padding: 40px 100px
    padding-top: 0
    @include mobile
      width: 100%
      padding: 30px

    h2
      color: #92877A
      font-family: 'archiveBold'
      text-align: left
      margin-bottom: 40px
      @include mobile
        font-size: 14px


    .input_wrapper
      position: relative
      margin-bottom: 30px
      label
        position: absolute
        left: 20px
        top: 20px
        color: #A3ABB2
        font-family: 'archiveBold'
        transition: all 0.5s
        &.active
          top: -25px
          left: 10px
          font-size: 14px
          color: #4f5153

      input
        width: 100%
        height: 60px
        margin-bottom: 20px
        border: none
        font-family: 'archiveBold'
        color: #A3ABB2
        font-size: 15px
        padding: 0 20px
        &:focus
          outline: 2px solid #7a7f84
    
    .checkbox_wrapper
      margin-bottom: 30px
      label
        color: #615751
        font-size: 15px
        font-family: 'amsiPro'
        span
          text-decoration: underline
          cursor: pointer

    button
      background: #615751
      border: none
      color: #D9D9D9
      font-family: 'archiveBold'
      padding: 10px 40px