@import '../styles/Mixin.sass'
.location
  display: flex
  position: relative
  @include mobile
    flex-direction: column-reverse

  p.title
    position: absolute
    top: 20px
    left: 30px
    font-family: 'archive'
    color: #615751
    font-size: 20px
    z-index: 99
    margin: 0
    @include mobile
      font-size: 10px
      left: 12px

  .map
    display: flex
    width: 50%
    @include mobile
      width: 100%
    img
      width: 100%
  
  
  .carrousel
    display: flex
    width: 50%
    position: relative
    @include mobile
      width: 100%
      padding: 30px
      background: #E6E2DB
    img.carrousel_image
      width: 100%
    img.left_arrow
      position: absolute
      top: 50%
      left: 40px
      transform: translateY(-50%)
      cursor: pointer
    img.right_arrow
      position: absolute
      top: 50%
      right: 40px
      transform: translateY(-50%)
      cursor: pointer