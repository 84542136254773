@import '../styles/Mixin.sass'
.project_text_section
  background: #8DA6BF
  padding: 50px 0
  @include mobile
    padding: 30px
  h2
    color: white
    font-family: 'archive'
    font-size: 33px
    margin: 0
    text-align: center
    @include mobile
      font-size: 12px
      text-align: center