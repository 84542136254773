@import './Mixin.sass'
.button
  padding: 10px 70px
  border: none
  width: fit-content
  @include mobile
    padding: 10px 45px
    font-size: 10px
  &.button-white
    color: #2A2A35
    background: white