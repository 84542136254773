@import '../styles/Mixin.sass'
.datasheet-text
  background: #A5783D
  display: flex
  align-items: center
  justify-content: center
  padding: 40px 0
  @include mobile
    padding: 30px
  h2
    color: white
    font-family: 'archive'
    font-size: 40px
    text-align: center
    @include mobile
      font-size: 12px