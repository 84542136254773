.menu_nav
  position: fixed
  top: 50px
  left: -100%
  background: #E6E2DB
  height: 100vh
  padding: 80px
  padding-left: 50px
  padding-top: 40px
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25)
  transition: left 0.5s ease-in-out
  z-index: 9999
  &.active
    left: 0
  ul
    display: flex
    flex-direction: column
    gap: 50px
    margin: 0
    padding: 0
    img
      width: 50%
      cursor: pointer
    li
      list-style: none
      cursor: pointer
      color: #615752
      font-family: 'archiveBold'