@import '../styles/Mixin.sass'

section.video
  background: #E6E2DB
  width: 100%
  padding: 60px 30px
  position: relative
  display: flex
  flex-direction: column
  align-items: center

  p.title
    position: absolute
    top: 20px
    left: 30px
    font-family: 'archive'
    color: #615751
    font-size: 20px
    margin: 0
    @include mobile
      font-size: 12px
      left: 10px
    

  .video_gif
    display: flex
    width: 60%
    height: 400px
    align-items: center
    justify-content: center
    @include mobile
      width: 100%
      height: 200px
      margin-bottom: 30px
    iframe
      width: 100%
      height: 100%

  .form
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    .form_title
      margin-bottom: 80px
      @include mobile
        margin-bottom: 30px
      h1
        font-size: 40px
        font-family: 'amsiProLight'
        color: #615751
        text-align: center
        @include mobile
          font-size: 15px
    
    .form_inputs
      display: flex
      justify-content: space-evenly
      gap: 20px
      margin-bottom: 50px
      width: 100%
      @include mobile
        flex-direction: column
        margin-bottom: 20px
      .form_input
        border-bottom: 1px solid #615751
        width: 25%
        position: relative
        @include mobile
          width: 100%
          margin-bottom: 30px
        input
          border: none
          background: none
          font-family: 'archiveBold'
          color: #615751
          font-size: 15px
          width: 100%
          padding-left: 10px
          padding-bottom: 5px
          @include mobile
            font-size: 12px

          &:focus
            outline: none
        label
          position: absolute
          bottom: 5px
          left: 10px
          color: #615751
          font-family: 'archiveBold'
          font-size: 15px
          transition: all 0.3s ease-in-out
          @include mobile
            font-size: 12px
          &.active
            color: #615751
            font-family: 'archiveBold'
            font-size: 12px
            transform: translateY(-30px)
            @include mobile
              font-size: 10px
    
    
    .form_checkbox
      margin-bottom: 50px
      label
        color: #615751
        font-family: 'amsiProLight'
        span
          text-decoration: underline
          cursor: pointer
    
    .form_submit
      button
        background: #615751
        color: #D9D9D9
        font-family: 'archiveBold'
        cursor: pointer
        transition: all 0.3s ease-in-out
        &:hover
          background: #7c7069