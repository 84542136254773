@import '../styles/Mixin.sass'

section.datasheet
  background: #A5783D
  display: flex
  max-height: 100vh
  justify-content: space-between
  position: relative
  @include mobile
    flex-direction: column
    max-height: none
    height: 100%

  p.title
    position: absolute
    top: 20px
    left: 30px
    font-family: 'archive'
    color: #E6E2DB
    font-size: 20px
    z-index: 99
    margin: 0
    @include mobile
      display: none
      font-size: 12px

  .ficha
    width: 50%
    display: flex
    align-items: center
    justify-content: center
    @include mobile
      width: 100%
    img.ficha_tecnica_desktop
      width: 60%
      @include mobile
        display: none

    img.ficha_tecnica_mobile
      display: none
      @include mobile
        display: block
        width: 100%
        margin-bottom: 30px

    .content
      color: #615751
      background-color: #E6E2DB
      display: flex
      flex-direction: column
      padding: 1rem 3rem
      border-color: #615751
      font-family: 'archive'
      @include mobile
        background-color: #a5783d
        color: #E6E2DB
        border-color: #E6E2DB
        text-align: center
        padding: .5rem
      .section
        justify-content: center
        padding: 1rem 0 1rem 0
        width: 100%
        display: flex
        border-bottom: 1px solid
        h1, h2
          margin: .5rem
          font-weight: 400
          font-size: large
        &:first-child
          h1, h2
            font-weight: 600
            font-size: larger
            @include mobile
              font-size: large
        &:last-child
          border-bottom: 0px

      .section.two
        gap: 2rem
        div
          display: flex
          flex-direction: column
          align-items: start
          justify-content: center
          &:first-child
            h2
              margin: 0
              strong
                font-size: 3rem
          &:last-child
            flex-direction: row
            align-items: center
            padding: 1rem 0 1rem 0
            .detail
              border-bottom: 1px solid
              border-left: 1px solid
              border-top: 1px solid
              width: 20px
              height: 100%
            div
              display: flex
              flex-direction: column
              align-items: start
              h2
                margin: 0

  .carrousel
    width: 50%
    display: flex
    justify-content: flex-end
    position: relative
    @include mobile
      width: 100%
      padding: 30px
      background: #E6E2DB
    .arrow_left
      position: absolute
      top: 50%
      left: 40px
      transform: translateY(-50%)
      width: 40px
      height: 40px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      transition: all 0.3s ease
      &:hover
        filter: opacity(0.8)
    .arrow_right
      position: absolute
      top: 50%
      right: 40px
      transform: translateY(-50%)
      width: 40px
      height: 40px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      transition: all 0.3s ease
      &:hover
        filter: opacity(0.8)
    img
      width: 100%
