@import '../styles/Mixin.sass'
.project
  display: flex
  width: 100%
  position: relative
  @include mobile
    flex-direction: column-reverse
    align-items: center
    padding: 0
  
  p.title
    position: absolute
    color: #E6E2DB
    top: 20px
    left: 30px
    font-family: 'archive'
    color: #615751
    font-size: 20px
    margin: 0
    z-index: 99
    @include mobile
      font-size: 12px
      color: #E6E2DB
      top: 10px
      left: 10px


  .people
    padding: 80px 40px
    padding-top: 120px
    width: 50%
    @include mobile
      width: 100%
    img
      width: 100%
  .image
    background: #8DA6BF
    padding: 60px
    padding-bottom: 0
    width: 50%
    display: flex
    flex-direction: column
    justify-content: space-between
    @include mobile
      width: 100%
      padding: 30px
      padding-top: 50px
      position: relative
    img.project_text
      width: 100%
    img.project_image
      height: 70%
      width: auto
      object-fit: cover
      @include mobile
        margin-bottom: -100px