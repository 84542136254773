.navbar
  display: flex
  justify-content: space-between
  padding: 20px
  align-items: center
  position: fixed
  width: 100%
  background: #E6E2DB
  z-index: 999999
  .menu
    color: #685454
    display: flex
    align-items: center
    gap: .5rem
    cursor: pointer
    svg
      height: 28px
      width: 28px
    img
      height: 20px
      @media (max-width: 768px)
        height: 16px !important
  img.menu_icon_navbar
    width: 60px
    cursor: pointer
  img.mozak
    height: 20px
  img.bsd
    height: 10px