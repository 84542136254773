$mobile-width: 430px
$tablet-width: 768px
$laptopS-width: 1440px
$laptop-width: 1900px

#timeline-mozak
    display: flex
    flex-direction: row
    gap: 2rem
    position: relative
    padding: 3rem 10% 5rem 10%
    background-color: #E6E2DB
    color: #615751
    @media (max-width: $mobile-width)
        padding: 0rem 0 5rem 0
        flex-direction: column
    .content
        display: flex
        flex-direction: column
        justify-content: center
        align-items: start
        padding: 0 4rem
        width: 100%
        height: auto
        @media (max-width: $mobile-width)
            padding: 0 1rem
            width: auto
            text-align: center
            align-items: center
        h1
            margin-top: 2rem
            font-size: 1.25rem
            font-weight: bold
            color: #615751
            text-decoration: underline
        p
            font-size: 1.25rem
            font-weight: 100
            margin-top: .5rem
            width: auto
            @media (max-width: $laptopS-width)
                width: auto

        a
            margin-top: .5rem
            padding: .5rem 1rem
            color: white
            text-decoration: unset
            transition: all 0.2s ease-in-out
            background: #615751
            border: none
            color: #D9D9D9
            font-family: "archiveBold"
            padding: 10px 40px
            &:hover
                background-color: #4c4440

    .comemorativa
        width: 50%
        filter: opacity(0.8)
        @media (max-width: $laptopS-width)
            width: 70%

    .swiper
        width: 100%
        aspect-ratio: 1/1
        border-radius: 5px 5px 50px 5px
        @media (max-width: $mobile-width)
            border-radius: 0
        .swiper-button-prev
            color: white
        .swiper-button-next
            color: white
        .swiper-wrapper
            .swiper-slide
                width: 100%
                height: 100%
                img
                    width: 100%
                    height: 100%
                    object-fit: cover
