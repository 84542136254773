@import '../styles/Mixin.sass'
footer
  background: #A5783D
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 50px 0
  @include mobile
    padding: 20px
  img
    margin-bottom: 20px
    @include mobile
      width: 40%
      margin-bottom: 15px
  p
    color: white
    @include mobile
      font-size: 10px
    span
      text-decoration: underline
      cursor: pointer

  span 
    color: white
    font-size: .8rem
    margin-top: 10px
    @include mobile
      font-size: .6rem