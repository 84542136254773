@import'../styles/Mixin.sass'
.hero
  display: flex
  flex-direction: column
  background: #8DA6BF
  padding-top: 60px
  height: 100vh
  @include mobile
    width: 100%
    position: relative
  .banner
    width:100%
    text-align: center
    padding-top: .5rem
    padding-bottom: .5rem
    margin: 0px
    color: #F4F4F4
    box-shadow: 0px 0px 7px rgba(0,0,0,0.3)
    z-index: 10
    display: flex
    flex-direction: column
    align-items: center
    justify-items: center
    gap: .5rem
    padding: 1rem 0rem 1rem 0rem
    h1, h2
      margin: 0
      @include mobile
        font-size: 1.6rem
    h2
      font-weight: normal
      @include mobile
        font-size: 1rem
        padding:  0rem 1rem 0rem 1rem
        text-wrap: balance
  
  .image
    display: flex
    position: relative
    height: 100%
    justify-content: center
    align-items: center
    overflow: hidden
    h1
      color: #63778b
      font-size: 32px
      padding: 0px 10px 0px 10px
      margin-bottom: 10px
      text-align: center
      background-color: #F4F4F4
      position: absolute
      right: -7rem
      top: 2rem
      width: 500px
      padding: .5rem 0 .5rem 0
      transform: rotate(25deg)
      z-index: 999
      font-weight: bold
      line-height: 1.9rem
      filter: drop-shadow(0px 3px 10px rgba(0,0,0,0.1))

      @include mobile
        font-size: 24px
        width: 500px
        right: -10rem
        top: 1rem
        // padding-top: 1rem
        // transform: rotate(0deg) translate(-50%)
        // left: 50%
        

    .content
      z-index: 12
      display: flex
      color: white
      flex-direction: column
      align-items: center
      filter: drop-shadow(0px 5px 5px rgba(0,0,0,0.1))
      @include mobile
        padding-bottom: 4rem
      img.logo_desktop
        width: 40%
        margin-bottom: 3rem
        margin-right: 20px
        @include mobile
          margin-bottom: 2rem
          width: 60%

      h1
        transform: rotate(0deg)
        padding: .4rem 1rem .4rem 1rem
        width: auto
        position: relative
        right: 0
        top: 0
        font-weight: lighter
        margin-bottom: .4rem
        background-color: #8DA6BF
        font-size: 22px
        strong
          font-weight: bold
      h2
        font-family: 'archive'
        color: #F4F4F4
        font-size: 24px
        font-weight: bold
        text-align: left
        padding: 0px 10px 0px 10px
        margin-top: 0
        margin-bottom: .3rem
        text-align: center

      h3
        font-family: 'archive'
        color: #F4F4F4
        font-size: 19px
        text-align: left
        font-weight: lighter
        padding: 0px 10px 0px 10px
        margin-top: 0
        margin-bottom: 20px
        text-align: center
      
      button
        color: #615751
        background: #E6E2DB
        font-family: 'archiveBold'
        border: none
        padding: 15px 30px
        margin-left: auto
        margin-right: auto
        @include mobile
          padding: 10px 20px


    .overlay
      position: absolute
      top: 0
      left:0
      width: 100%
      height: 100%
      background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.3) 100%)
      // background-color: rgba(0,0,0,0.3)
      z-index: 10
      @include mobile
        background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.3) 100%)

    img.image_desktop
      position: absolute
      top: 0
      left:0
      width: 100%
      height: 100%
      object-fit: cover
      object-position: top
      background-color: rgba(0,0,0,0.2)
      @include mobile
        display: none
    
    img.image_mobile
      position: absolute
      top: 0
      left:0
      width: 100%
      height: 100%
      object-fit: cover
      object-position: top
      background-color: rgba(0,0,0,0.2)
      display: none
      @include mobile
        display: block
      @include laptop
        display: none